<template src="./quiz-results.html"></template>
<script>
import quizService from "../../services/quiz-service";
import appHeader from "../../../shared-module/components/app-header/app-header.vue";
var chevronListBox;
var feedback = false;
import { exportToCSV } from "./../../services/csvService";
export default {
  name: "app-quiz-results",
  components: {
    "app-header": appHeader,
  },
  data: () => {
    return {
      feedback,
      quizId: "",
      sessionId: "",
      searchResults: "",
      userName: "",
      instituteName: "",
      userEmail: "",
      userSearchResults: "",
      searchFlag: true,
      quizName: "",
      quizDuration: "",
      countQuestions: "",
      totalMarks:0
    };
  },
  mounted() {
    if (this.$route.query.sessionId) {
      this.sessionId = this.$route.query.sessionId;
      this.getResultsBySessionId();
    } else if (this.$route.query.quizId) {
      this.quizId = this.$route.query.quizId;
      this.getResultsByQuizId();
      this.getCountOfQuesstions();
    }
  },
  methods: {
    async getCountOfQuesstions() {
      try {
        let response = await quizService.getQuizQuestionCount(this.quizId);
        this.countQuestions = response.data.data.question;
      } catch (error) {
        console.error(error);
      }
    },
    chevronListBoxHandler() {
      this.chevronListBox = !this.chevronListBox;
    },
    async getSessionByQuizId() {
      try {
        let response = await quizService.getSessionsByQuizId(this.quizId);

        let minuts = response.data.data.sessions[0].quiz_id.duration;
        let minutes = Math.floor(minuts / 60);
        this.quizDuration = minutes;
        this.sessionId = response.data.data.sessions[0]._id;
        this.getResultsBySessionId();
      } catch (error) {
        console.error(error);
      }
    },
    async getResultsBySessionId() {
      try {
        let response = await quizService.getResultsBySessionId(this.sessionId);
        this.searchResults = response.data.data.results;
        this.quizName = response.data.data.results[0].quiz.name;
        this.countQuestions = response.data.data.results[0].questions.length;
      } catch (error) {
        console.error(error);
        this.$toast.error("Results Not Found");
      }
    },
    async getResultsByQuizId() {
      try {
        let response = await quizService.getResultsByQuizId(this.quizId);
        this.searchResults = response.data.data.results;
        this.totalMarks = response.data.data.totalMarks;
        this.quizName = response.data.data.results[0].quiz.name;
      } catch (error) {
        console.error(error);
        this.$toast.error("Results Not Found");
      }
    },
    searchFlagHandler(args) {
      if (args === 1) {
        this.searchFlag = false;
      } else {
        this.searchFlag = true;
      }
    },
    async searchUser(key) {
      try {
        let data = { quiz_id: this.quizId };
        data["keyword"] = this[key];
        data["key"] = key;
        let response = await quizService.searchUser(data);
        if (response) {
          this.userSearchResults = response.data.data.response;
          this.searchFlagHandler(1);
          this.userName = "";
          this.instituteName = "";
          this.userEmail = "";
        }
      } catch (error) {
        this.$toast.error("Search not Found!");
      }
    },
    exportCSV(x) {
  // if (!x) return;
   // Validate input
  
  console.log(this.searchFlag);
  const results = [];
  const headers = [
    "Student ID",
    "Signup Date",
    "Student Name",
    "Email",
    "Phone",
    "Total Obtained Marks",
    "Obtained Marks Percentage",
    "Date",
    "City",
    "Institute",
    "Time/Question",
    "Obtained Marks",
    "Skipped Count",
    "Marked Questions",
    "Result",
  ];
  results.push(headers);

  const d_to_iterate = this.searchFlag
    ? this.searchResults
    : this.userSearchResults;
  console.log(d_to_iterate);

  if (!d_to_iterate || d_to_iterate.length === 0) {
    console.error("No data to export.");
    return;
  }

  d_to_iterate.forEach((result, index) => {
    let city = "";
    let uni = "";
    let correct_percentage = 0;

    if (result.user?.city) {
      city = result.user.city.replace(/,/g, " ");
    }

    if (result.user?.university) {
      uni = result.user.university.replace(/,/g, " ");
    }

    if (result.correct_percentage) {
      correct_percentage = result.correct_percentage;
    }

    const skipped_count =
      result.skipped_questions.length > result.questions.length
        ? result.skipped_questions.length - 1
        : result.skipped_questions.length;

    let data = [
      result.user.id || "",
      result.user.created_at || "",
      result.user.full_name || "",
      result.user.email || "",
      result.user.phone || "",
      result?.marks_obtained, // Rounded to two decimal places
      correct_percentage || 0,
      result.created_at || "",
      city,
      uni,
      result.time_spent || "",
      result.obtained_marks || "",
      skipped_count || 0,
      result.marked_questions.length || 0,
      result.result ? "pass" : "fail",
    ];
    results.push(data);
    console.log(data);
  });

  // Export results to CSV
  const csvContent = results.map(row => row.join(",")).join("\n");
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", "export.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
,
    gotoResult(result) {
      const quiz = result.quiz;
      if(quiz.quiz_type === 'daq') {
        // const url = `${process.env.VUE_APP_SHARE_ABLE_LINK_POP}course/${quiz.module.index_url}/${quiz.module_id}/${quiz._id}?daq=true&daq_id=${quiz._id}&result=true&result_id=${result._id}&courseIndexUrl=${quiz.course.index_url}`;
        // window.open(url, '_blank');
        this.$router.push('/quiz/individual-results-daq?resultId='+result._id); 
      } else {
        this.$router.push('/quiz/individual-results?resultId='+result._id); 
      }
    },
  },
};
</script>

<style src="./quiz-results.css" scoped></style>
